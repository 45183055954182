<template>
  <div class="options-container">
    <OptionCard @next-step="$emit('next-step', 'outside-interest')">
      <template #icon><img :src="OutsideInterestIcon" alt="" /></template>
      <template #content>
        <p>{{ $t('DISCLOSURE_HOME.OUTSIDE_INTEREST_TITLE') }}</p>
        <p>
          {{ $t(outsideInterest.text, { companyName: company.name }) }}
          <button
            class="view-toggler"
            @click.stop="toggleView('outsideInterest')"
          >
            {{ $t(outsideInterest.viewToggler) }}
          </button>
        </p>
      </template>
    </OptionCard>
    <OptionCard @next-step="$emit('next-step', 'gifts')">
      <template #icon><img :src="GiftsIcon" alt="" /></template>
      <template #content>
        <p>{{ $t('DISCLOSURE_HOME.GIFTS_TITLE') }}</p>
        <p>
          {{ $t(gifts.text, { companyName: company.name }) }}
          <button class="view-toggler" @click.stop="toggleView('gifts')">
            {{ $t(gifts.viewToggler) }}
          </button>
        </p>
      </template>
    </OptionCard>
    <OptionCard @next-step="$emit('next-step', 'outside-relationship')">
      <template #icon><img :src="OutsideRelationshipIcon" alt="" /></template>
      <template #content>
        <p>{{ $t('DISCLOSURE_HOME.OUTSIDE_RELATIONSHIP_TITLE') }}</p>
        <p>
          {{ $t(outsideRelationship.text, { companyName: company.name }) }}
          <button
            class="view-toggler"
            @click.stop="toggleView('outsideRelationship')"
          >
            {{ $t(outsideRelationship.viewToggler) }}
          </button>
        </p>
      </template>
    </OptionCard>
    <OptionCard @next-step="$emit('next-step', 'inside-relationship')">
      <template #icon><img :src="InsideRelationshipIcon" alt="" /></template>
      <template #content>
        <p>{{ $t('DISCLOSURE_HOME.INSIDE_RELATIONSHIP_TITLE') }}</p>
        <p>
          {{ $t(insideRelationship.text, { companyName: company.name }) }}
          <button
            class="view-toggler"
            @click.stop="toggleView('insideRelationship')"
          >
            {{ $t(insideRelationship.viewToggler) }}
          </button>
        </p>
      </template>
    </OptionCard>
    <OptionCard
      @next-step="$emit('next-step', 'other-potencial-conflit-interest')"
    >
      <template #icon>
        <img :src="otherPotencialConflitInterestIcon" alt="" />
      </template>
      <template #content>
        <p>
          {{
            $t(
              'DISCLOSURE_HOME.OTHER_POTENCIAL_CONFLIT_INTEREST_DESCRIPTION_TITLE',
            )
          }}
        </p>
        <p>
          {{
            $t(otherPotencialConflitInterest.text, {
              companyName: company.name,
            })
          }}
          <button
            class="view-toggler"
            @click.stop="toggleView('otherPotencialConflitInterest')"
          >
            {{ $t(otherPotencialConflitInterest.viewToggler) }}
          </button>
        </p>
      </template>
    </OptionCard>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OptionCard from '@/components/OptionCard'
import OutsideInterestIcon from '@/assets/images/disclosure/outside-interest.svg'
import GiftsIcon from '@/assets/images/disclosure/gifts.svg'
import OutsideRelationshipIcon from '@/assets/images/disclosure/outside-relationship.svg'
import InsideRelationshipIcon from '@/assets/images/disclosure/inside-relationship.svg'
import otherPotencialConflitInterestIcon from '@/assets/images/disclosure/other-potencial-conflit-interest.svg'

export default {
  name: 'OptionsContainer',

  components: {
    OptionCard,
  },

  data() {
    return {
      OutsideInterestIcon,
      GiftsIcon,
      OutsideRelationshipIcon,
      InsideRelationshipIcon,
      otherPotencialConflitInterestIcon,
      outsideInterest: {
        text: 'DISCLOSURE_HOME.OUTSIDE_INTEREST_DESCRIPTION.SNIPPET',
        isComplete: false,
        viewToggler: 'DISCLOSURE_HOME.SEE_MORE',
      },
      gifts: {
        text: 'DISCLOSURE_HOME.GIFTS_DESCRIPTION.SNIPPET',
        isComplete: false,
        viewToggler: 'DISCLOSURE_HOME.SEE_MORE',
      },
      outsideRelationship: {
        text: 'DISCLOSURE_HOME.OUTSIDE_RELATIONSHIP_DESCRIPTION.SNIPPET',
        isComplete: false,
        viewToggler: 'DISCLOSURE_HOME.SEE_MORE',
      },
      insideRelationship: {
        text: 'DISCLOSURE_HOME.INSIDE_RELATIONSHIP_DESCRIPTION.SNIPPET',
        isComplete: false,
        viewToggler: 'DISCLOSURE_HOME.SEE_MORE',
      },
      otherPotencialConflitInterest: {
        text: 'DISCLOSURE_HOME.OTHER_POTENCIAL_CONFLIT_INTEREST_DESCRIPTION.SNIPPET',
        isComplete: false,
        viewToggler: 'DISCLOSURE_HOME.SEE_MORE',
      },
    }
  },
  computed: mapState(['company']),

  methods: {
    toggleView(item) {
      const key = {
        outsideInterest: 'OUTSIDE_INTEREST',
        gifts: 'GIFTS',
        outsideRelationship: 'OUTSIDE_RELATIONSHIP',
        insideRelationship: 'INSIDE_RELATIONSHIP',
        otherPotencialConflitInterest: 'OTHER_POTENCIAL_CONFLIT_INTEREST',
      }
      const type = this[item].isComplete ? 'SNIPPET' : 'COMPLETE'
      this[item].text = `DISCLOSURE_HOME.${key[item]}_DESCRIPTION.${type}`
      this[item].isComplete = !this[item].isComplete
      const key2 = this[item].isComplete ? 'SEE_LESS' : 'SEE_MORE'

      this[item].viewToggler = `DISCLOSURE_HOME.${key2}`
    },
  },
}
</script>

<style scoped>
.options-container {
  margin-top: 15px;
}
.view-toggler {
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}
</style>
