<template>
  <section class="disclosure-container">
    <s-container large>
      <disclosure-header />

      <div class="disclosure-container__form">
        <p class="disclosure-home__instruction">
          {{ $t('DISCLOSURE_HOME.INSTRUCTION') }}
        </p>
        <div class="disclosure-home__options">
          <OptionsContainer @next-step="nextStep" />
        </div>
      </div>
    </s-container>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import DisclosureHeader from '@/components/Disclosure/DisclosureHeader'
import OptionsContainer from '@/components/Disclosure/OptionsContainer'
import SContainer from '@/components/ui/s-container'

export default {
  name: 'DisclosureHome',

  components: {
    DisclosureHeader,
    OptionsContainer,
    SContainer,
  },

  computed: mapState(['company', 'isLoggedIn']),

  async beforeMount() {
    if (this.$store.getters.isLoggedIn) {
      return
    }

    const { slug, slugId, agree } = this.$route.params

    if (!agree) {
      this.$router.push({ name: 'company', params: { slug, slugId } })
    }
  },

  methods: {
    ...mapMutations(['mutate']),

    back() {
      this.$router.go(-1)
    },

    nextStep(step) {
      const params = {
        agree: true,
        source: this.source,
        slug: this.company.slug,
        slugId: this.company.slugId,
      }

      this.mutate({ property: 'relationship', value: this.source })
      this.mutate({ property: 'loading', value: false })

      this.$router.push({ params, name: step })
    },
  },
}
</script>

<style scoped>
.disclosure-home {
  padding-top: 15px;
}
.disclosure-home__header {
  text-align: center;
}
.disclosure-home__header .back {
  background: none;
  border: none;
  cursor: pointer;
}
.disclosure-home__title {
  display: flex;
  align-items: center;
  gap: 42px;
  margin: 25px 0 10px;
}
.disclosure-home__title h1 {
  color: var(--color-green-default);
  font-size: 23px;
  font-weight: 700;
}
.disclosure-home__title h1 span {
  font-weight: 300;
  margin-left: 5px;
  font-size: 20px;
}
.disclosure-home__help {
  margin: 30px 0 0;
  color: var(--color-grey-lighter);
  font-size: 14px;
}
.disclosure-home__instruction {
  margin: 30px 0;
  font-weight: 700;
  color: var(--color-green-default);
}
</style>
